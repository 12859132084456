* {
  padding: 0px;
  margin: 0px;
  border: none;
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: "Inter", sans-serif;
}

/* <!-- ======================== Root ======================== --> */
:root {
  --main-color: #00b2ff;
  --background-color: #f5f6ff;
  --Dark-color: #202632;
  --Dark2-color: #121631;
  --side-bar-color: #202632;
  --card-color: #ffffff;

  --color-text: #2c363a;

  --wight-color: #ffffff;
  --gray-color: #b4bcd0;

  --main-color-hover: #2495ffdb;
  --Dark2-color-hover: #292f51;
}

/* :root {
  --main-color: #00B2FF;
  --background-color: #080A1F;
  --Dark-color: #202632;
  --Dark2-color: #121631;
  --side-bar-color: #202632;
  --card-color: #181A2F;

  --color-text: #DFF5FF;

  --wight-color: #ffffff;
  --gray-color: #b4bcd0;

  --main-color-hover: #2495ffdb;
  --Dark2-color-hover: #292f51;
} */

a {
  color: var(--wight-color);
}

p {
  line-height: 25px;
  color: var(--color-text);
}

img {
  height: auto;
  width: auto;
}

.button {
  color: var(--wight-color);
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 95px;
  background: var(--main-color);
  font-weight: 500;
  padding: 15px 20px;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;

  gap: 10px;
    align-items: center;
}

.button:hover {
  background: var(--main-color-hover);
}

.flex-div-align {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon {
  width: 24px;
  height: 24px;
}

::-webkit-input-placeholder {
  color: var(--gray-color) !important;
  font-size: 16px;
  font-weight: 400 !important;
}

::-moz-placeholder {
  color: var(--gray-color) !important;
  font-size: 16px;
  font-weight: 400 !important;
}

:-ms-input-placeholder {
  color: var(--gray-color) !important;
  font-size: 16px;
  font-weight: 400 !important;
}

::-ms-input-placeholder {
  color: var(--gray-color) !important;
  font-size: 16px;
  font-weight: 400 !important;
}

::placeholder {
  color: var(--gray-color) !important;
  font-size: 16px;
  font-weight: 400 !important;
}

::-moz-selection {
  background-color: var(--main-color);
  color: whitesmoke !important;
  background-clip: initial !important;
  -webkit-background-clip: initial !important;
  -webkit-text-fill-color: initial !important;
  background-image: initial !important;
}

::selection {
  background-color: var(--main-color);
  color: whitesmoke !important;
  background-clip: initial !important;
  -webkit-background-clip: initial !important;
  -webkit-text-fill-color: initial !important;
  background-image: initial !important;
}

input::placeholder {
  text-transform: capitalize;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
}

.container-page {
  margin-top: 150px;
}

/* <!-- ======================== title-section ======================== --> */

.title-section {
  text-align: center;
  margin: 90px 0px;
  margin-top: 200px;
  position: relative;
  z-index: -1;
}
.our-services .title-section {
  margin: 50px 0px;
  margin-top: 80px;
}

.title-section h2 {
  color: rgb(185, 185, 185);
}

.title-section {
  line-height: 36px;
}

.title-section h1 {
  position: relative;
  text-transform: capitalize;
  margin-bottom: 5px;
  color: var(--main-color);
  font-size: 45px;
}

.title-section h1::after {
  width: 70%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    #2a185b,
    rgba(0, 212, 255, 0) 100%
  );
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  border-radius: 45px;
  width: 100%;
  max-width: 300px;
  /* animation: width 2s infinite ease; */
}

.title-section h1::before {
  content: url("./assets/imgs/elment/lines-background.svg");
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  z-index: -1;
  user-select: none;
  pointer-events: none;
}

.title-section p {
  color: var(--color-text);
  max-width: 700px;
  margin: auto;
}

@media (max-width: 500px) {
  .title-section h1 {
    font-size: 37px !important;
  }
}


.icon-serv img{
  width: 51px;
}
/* <!-- ======================== Card Grid ======================== --> */

.grid-style {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(374px, 1fr));
  position: relative;
  gap: 20px;
}

@media (max-width: 400px) {
  .grid-style {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.card {
  text-align: center;
  text-transform: capitalize;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  padding-bottom: 30px;
  border-radius: 72px;
  background: var(--card-color);
  /* box-shadow: 0px 24px 54px 0px #DFE2EE; */
  position: relative;
  z-index: 1;
  padding: 50px 20px;
}

.shadow-card {
  content: "";
  position: absolute;
  width: 70%;
  height: 35px;
  border-radius: 72px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 24px 54px 0px rgba(223, 226, 238, 0.5);
  left: 50%;
  transform: translateX(-50%);
  bottom: -34px;
  z-index: 0;
  border-radius: 0 0 25px 25px;
}

.shadow-card2 {
  content: "";
  position: absolute;
  width: 56%;
  height: 35px;
  border-radius: 72px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 24px 54px 0px rgba(223, 226, 238, 0.5);
  left: 50%;
  transform: translateX(-50%);
  bottom: -69px;
  z-index: -1;
  border-radius: 0 0 25px 25px;
}

/* .card::before {
  content: "";
  position: absolute;
  width: 70%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  z-index: 0;
  border-radius: 72px;
  background: rgba(255, 255, 255, 0.11);
  box-shadow: 0px 24px 54px 0px rgba(223, 226, 238, 0.20);
  
} */

.div-img-bc {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
}

.icon-card img {
  height: 50%;
}

.icon-card {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 5px auto;
  -webkit-backdrop-filter: blur(85px);
  backdrop-filter: blur(85px);
  border-radius: 28px;
  background: var(--main-color, #243aff);
  margin-bottom: 20px;
  overflow: hidden;
}

.icon-color1 {
  background-color: #dff8f1;
}

.icon-color2 {
  background-color: #ffe2e2;
}

.icon-color3 {
  background-color: #d4edfb;
}

.content-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 15px;
}

.content-card p {
  color: var(--gray-color);
}

.content-card h1 {
  color: var(--color-text);
}

.card-light {
  position: relative;
  overflow: hidden;
}

.card-light:after {
  content: "";
  position: absolute;
  inset: 0;
  background: -o-radial-gradient(
    calc(var(--ratio-x) * 100%) calc(var(--ratio-y) * 100%),
    circle,
    #81a2fc1e,
    transparent 50%
  );
  background: radial-gradient(
    circle at calc(var(--ratio-x) * 100%) calc(var(--ratio-y) * 100%),
    #81a2fc1e,
    transparent 50%
  );
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

/* <!-- ======================== social-media ======================== --> */

.social-media {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 5;
  margin-top: 10px;
}

.social-media span {
  font-size: 30px;
  margin: 5px;
  background-color: #272e4b2f;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  position: relative;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.social-media span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(360deg, #6131df61, transparent);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
}

.social-media span:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.social-media i {
  color: white;
  transition: 0.5s;
}

.social-media a {
  margin: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: relative;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
}

.social-media a:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
}

.social-media img {
  width: 70%;
  height: 24px;
  filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg)
    brightness(107%) contrast(105%);
  -webkit-filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg)
    brightness(107%) contrast(105%);
}

/* <!-- ======================== Body ======================== --> */

body {
  color: var(--wight-color);
  overflow-x: clip;
  /* background: var(--background-color); */
  background: linear-gradient(45deg, white, #ecf1fe, white);
}

.container {
  width: 95%;
  margin: 0px auto;
  max-width: 1235px;
}

/* ======================== Header ======================== */
header {
  padding: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  z-index: 100;
}

header::after {
  content: "";
  width: 80%;
  position: absolute;
  bottom: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(2, 0, 36, 0)),
    color-stop(49%, rgb(64, 79, 110)),
    to(rgba(0, 212, 255, 0))
  );
  background: -o-linear-gradient(
    left,
    rgba(2, 0, 36, 0) 0%,
    rgb(64, 79, 110) 49%,
    rgba(0, 212, 255, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgb(64, 79, 110) 49%,
    rgba(0, 212, 255, 0) 100%
  );
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

header .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.register-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 100%;
  position: relative;
  min-width: 196px;

  justify-content: end;
}

.register-div .login {
  color: var(--wight-color);
  background-color: #1d213b00;
}

.login {
  color: var(--color-text) !important;
}

.register-div .sing-up:hover {
  background-color: var(--main-color-hover);
}

.a-logo img {
  height: 60px;
  aspect-ratio: 3/1.51;
}
.logo-icon-header {
 aspect-ratio: 1/1 !important;

}

.a-logo {
  min-width: 196px;
}

.sing-up-div {
  position: relative;
}

.down-sing-up {
  color: var(--Dark-color);
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  text-align: center;
  right: 0 !important;
  top: 70px;
  padding: 15px !important;
  width: 220px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  border: initial !important;
  animation: jumpInfinite 2s infinite;
  -webkit-animation: jumpInfinite 2s infinite;
  display: none;
}

.down-sing-up::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid var(--main-color);
  top: -15px;
  right: 28px;
}

.down-sing-up.active {
  display: block !important;
}

.list-website {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.phone-header .list-website {
  display: none;
}

.list-website a {
  color: var(--color-text);
  font-weight: 700;
}

.toggle {
  cursor: pointer;
}

.toggle-div {
  padding: 10px;
}


@media (max-width: 500px) {
  header .account-parent .name-username {
    display: none;
  }
  .get-started-div .button {
    height: 60px;
    min-width: 210px;
  }
  .logo-icon-header {
    display: block !important;
  }

  .logo-header {
    display: none;
  }

  header .down-li-account-parent {
    width: 150px !important;
  }
}

@media (min-width: 500px) {
  .logo-icon-header {
    display: none;
  }
}

/* <!-- ======================== img Style ======================== --> */

.main-sec .style-img-div img {
  height: 550px;
  right: -150px !important;
}
.style-img-div {
  position: absolute;
  width: 100%;
  overflow-x: clip;
  top: 0;
  height: 510px;
  user-select: none;
  z-index: 0;
  pointer-events: none;
}

.style-img-div img {
  position: absolute;
  height: 760px;
}

.left-img-div img {
  top: -150px;
  left: -195px;
}

.right-img-div img {
  right: -194px !important;
  -webkit-transform: scaleX(-1) !important;
  transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -ms-transform: scaleX(-1) !important;
  -o-transform: scaleX(-1) !important;
}

.iframe-map {
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
  height: 100%;
}
.iframe-map iframe {
  height: 100%;
  width: 100%;
  min-height: 300px;
}
/* <!-- ======================== Main ======================== --> */

main {
  min-height: 730px;
  position: relative;
  padding: 163px 0;
  margin-top: -53px;
  overflow: hidden;
}

.main-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  gap: 15px;
  align-items: center;
  position: relative;
  z-index: 5;
}

@media (max-width: 1450px) {
  .main-text {
    width: 90% !important;
  }
}

@media (max-width: 1024px) {
  main .main-text h1 {
    font-size: 35px !important;
  }

  main .main-text h2 {
    font-size: 25px !important;
  }

  .flex-div-align {
    justify-content: inherit !important;
  }

  .hero-img {
    width: 410px !important;
  }

  main {
    padding: 184px 0;
  }

  .title {
    text-align: start;
  }
}

@media (max-width: 1050px) {
  .list-website{
    gap: 10px !important;
  }
  main {
    padding-bottom: 80px;
  }

  .main-content {
    gap: 70px;
  }

  .element {
    display: none;
  }

  .main-text {
    text-align: center;
    width: 100% !important;
  }

  .flex-div-align {
    justify-content: center;
  }
}

.hero-img {
  width: 530px;
  animation: moveUpAndDown 5s ease infinite;
  position: relative;
  aspect-ratio: 4/4;

}

@keyframes moveUpAndDown {
  0% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(10px);
  }
}

.website-main-sec {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

main .main-text h1 {
  color: var(--main-color);
  margin-bottom: 10px;
  font-size: 55px;
}

main .main-text h2 {
  color: var(--wight-color);
  font-size: 55px;
  color: var(--color-text);
}

.main-text .button {
  min-width: 280px;
  height: 70px;
}

.title {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.title p {
  font-size: 20px;
  line-height: 32px;
}

.phone-div-flow-imgs {
  position: relative;
  aspect-ratio: 4/4;
}

.subscription-form {
  border-radius: var(--main-border-radius);
  background: var(--Dark2-color-no-change);
  display: flex;
  width: 85%;
  margin-top: 30px;
}

.subscription-form .input-subscription {
  background: none;
  width: 100%;
  color: var(--wight-color-no-change);
  padding: 0 10px;
}

.submit-subscription {
  padding: 5px;
  background: var(--main-color);
  border-radius: 5px;
  cursor: pointer;
}

.submit-subscription img {
  width: 45px;
}

.submit-subscription:hover {
  background-color: #f76332;
}

.subscription-form .input-subscription::placeholder {
  color: var(--gray-color);
  font-weight: 100;
}

/* Poster Flow  ---------------------------------- --> */

.poster-flow {
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: var(--main-color);
  border-radius: 50%;
  z-index: 55;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: MoveUpPoster 3s ease infinite;
}

.poster-flow::after {
  content: "";
  position: absolute;
  width: 180px;
  height: 180px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  border: 2px solid #fff300;
}

.img-flow {
  width: 100%;
  height: 100%;
  overflow: hidden;
  width: 150px;
  border-radius: 50%;
}

.poster1 {
  box-shadow: 15px 15px 30px #0000003d;
}

.poster1 img {
  transform: scale(1.1);
}

.poster2 {
  animation: MoveUpPoster 2s ease infinite;
  box-shadow: -20px -18px 30px #0000003d;
}

@keyframes MoveUpPoster {
  0% {
    transform: rotate(13deg) translateY(10px);
  }

  50% {
    transform: rotate(13deg) translateY(-5px);
  }

  100% {
    transform: rotate(13deg) translateY(10px);
  }
}

.poster1 {
  left: -30px;
}

.poster2 {
  right: -40px;
  bottom: 11px;
}

.poster-flow img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 500px) {
  .website-main-sec .hero-img {
    width: 95% !important;
    margin: auto;
  }

  .container {
    width: 90%;
  }

  .poster-flow {
    width: 125px;
    height: 125px;
  }

  .poster-flow::after {
    width: 145px;
    height: 145px;
  }
}

/* Pop Character  ---------------------------------- --> */

.pop-img-div {
  width: 430px;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 5;
  animation: MoveUpPosterpop 4s ease;
  -webkit-animation: MoveUpPosterpop 4s ease;
}

.pop-img-div img {
  width: 50%;
  aspect-ratio: 4.9/1.51;
}

.pop1 {
  top: -7px;
  left: 45px;
  animation: moveUpAndDown 4s ease infinite;
  -webkit-animation: moveUpAndDown 4s ease infinite;
}

.pop2 {
  right: -52px;
  bottom: 96px;
  animation: moveUpAndDown 7s ease infinite;
}

.pop3 {
  bottom: 51px;
  left: 24px;
  animation: moveUpAndDown 6s ease infinite;
}

@media (max-width: 600px) {
  .pop2 {
    right: 8px;
    bottom: 86px;
  }

  .pop1 {
    top: 23px;
    left: 5px;
  }

  .pop-img-div {
    width: 200px;
  }
}

/* play-video-icon ---------------------------------- --> */

.main-sec .play-video-icon {
  top: 50%;
}
.play-video-icon img {
  width: 64px;
  aspect-ratio: 4/4;
}

.play-video-icon {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #0e11359f; */
  background: #e8eaff9f;
  border-radius: 50%;
  backdrop-filter: blur(6px);
  z-index: 555;
  cursor: pointer;
  position: relative;
}

.play-video-icon::after {
  content: "";
  position: absolute;
  width: 80px;
  height: 80px;
  /* background-color: #0e11359f; */
  background-color: #f2f7ff9f;
  z-index: -1;
  opacity: 0.5;
  border-radius: 50%;
  animation: scale 5s ease infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.play-video-icon img {
  filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg)
    brightness(107%) contrast(105%);
}

/* <!-- ======================== Our Services ======================== --> */

.services-grid .card:nth-child(1),
.services-grid .card:nth-child(3),
.services-grid .card:nth-child(4),
.services-grid .card:nth-child(6),
.services-grid .card:nth-child(7),
.services-grid .card:nth-child(9),
.services-grid .card:nth-child(10),
.services-grid .card:nth-child(12),
.services-grid .card:nth-child(13),
.services-grid .card:nth-child(15),
.services-grid .card:nth-child(16),
.services-grid .card:nth-child(18) {
  margin-top: 55px;
}

.container-page{
  overflow-x: clip;
}

.container-page .services-grid .card {
  margin-top: 0 !important;
}

.services-grid .card {
  height: fit-content !important;
}

/* <!-- ======================== Our Services Page Details ======================== --> */

.services-page-container{
  margin-top: 150px;
}

/* <!-- ======================== Our Team ======================== --> */
.team-grid .icon-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 4/4;

}

.content-team {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  gap: 15px;
  align-items: center;
  position: relative;
  z-index: 5;
}

.content-team .img-about-div {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
}
.content-team .img-about-div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about-text {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.about-text h1{
  color: var(--main-color);
}
.about-text h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}
/* <!-- ======================== Our Team Page ======================== --> */

.team-page-container {
  margin-top: 150px;
}

/* <!-- ======================== Courses Sec ======================== --> */

.courses-div-parent {
  display: flex;
  gap: 10px;
  align-items: center;
}

.courses-div {
  background: var(--Dark-color);
  overflow: hidden;
  border-radius: 5px;
  max-width: 350px;
  transition: 0.3s;
}

.courses-div:hover {
  transform: translateY(-5px) !important;
}

.details-course {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title-course a {
  color: var(--wight-color);
}

.thumbnail-div {
  overflow: hidden;
  position: relative;
}

.thumbnail-div .thumbnail-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-thumbnail {
  height: 200px;
  display: block;
}

.time-div {
  position: absolute;
  font-family: 'DINNextLTArabic-Regular' !important;
}

.time-div {
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  background: var(--Dark2-color-no-change);
  padding: 9px 15px;
  border-radius: 60px;
  gap: 5px;
  font-size: 14px;
  color: var(--wight-color-no-change);
}

.time-div img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg)
    brightness(103%) contrast(103%);
  width: 17px;
}

.category-div {
  display: flex;
  gap: 5px;
  font-family: 'DINNextLTArabic-Regular' !important;
}

.category-div span {
  padding: 5px 10px;
  background-color: var(--Dark2-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.stage-div {
  top: 5px;
  left: 5px;
  display: flex;
  gap: 5px;
  background-color: var(--Dark2-color-no-change);
  padding: 5px 10px;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  color: var(--wight-color-no-change);
  font-family: 'DINNextLTArabic-Regular' !important;
}

.profile-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-course {
  padding: 10px 20px;
  border-radius: 50px;
  background-color: var(--main-color);
}

.price-course p {
  color: var(--wight-color-no-change);
}

.profile-div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.prof {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  flex-shrink: 0;
}

.prof img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name-profile-about {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name-profile-about h2 {
  font-size: 18px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--wight-color);
}

.name-profile-about p {
  font-size: 14px;
  color: var(--gray-color);
  font-weight: 300;
}

.details-footer {
  padding-top: 15px;
  border-top: 1px solid #56708d;
  margin-top: 15px;
}

.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.heart-div {
  display: flex;
  padding: 5px;
  width: 40px;
  height: 40px;
  background: var(--Dark2-color-no-change);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}

.heart-div:active {
  transform: scale(0.95);
}

.heart-div:hover {
  transform: scale(1.05);
}

.heart-div img {
  width: 24px;
}

.heart-div img.heart-color {
  filter: invert(59%) sepia(132%) saturate(2043%) hue-rotate(345deg)
    brightness(97%) contrast(117%);
  -webkit-filter: invert(59%) sepia(132%) saturate(2043%) hue-rotate(345deg)
    brightness(97%) contrast(117%);
}

.popular-div {
  padding: 5px 10px;
  background-color: var(--Dark2-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.rate-div {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 5px;
  background-color: var(--Dark2-color-no-change);
  bottom: 10px;
  left: 5px;
  border-radius: 20px;
  color: var(--wight-color-no-change);
}

.rate-div img {
  width: 20px;
  filter: invert(49%) sepia(60%) saturate(6450%) hue-rotate(353deg)
    brightness(96%) contrast(91%);
}

.add-to-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: var(--main-color);
  width: 41%;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  transition: 0.2s;
}

.add-to-cart:active {
  transform: scale(0.95);
}

.add-to-cart:hover {
  transform: scale(1.05);
}



.courses-container {
  margin-top: 70px;
}

.toggle-stage {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
  overflow: scroll;
}

.toggle-stage li {
  display: flex;
  align-items: center;
  background: var(--Dark-color);
  padding: 9px 15px;
  border-radius: 60px;
  gap: 5px;
  font-size: 14px;
  cursor: pointer;
  flex-shrink: 0;
}

.toggle-stage::-webkit-scrollbar {
  display: none;
}

.toggle-stage li.active {
  background-color: var(--main-color);
  opacity: 1 !important;
  color: var(--wight-color-no-change);
}

.toggle-stage li:hover {
  opacity: 0.7;
}

.courses-container-toggle {
  position: relative !important;
  height: 460px;
}

.toggle-courses {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.toggle-courses.active {
  display: contents;
}

/* <!-- ======================== company-grid ======================== --> */

.company-img-partner {
  padding: 10px;

  margin: auto;
  margin-top: 35px;
  overflow-x: clip;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 50px;
  max-width: 950px;
  padding: 10px 110px;
  overflow-x: scroll;
}

 .company-img-partner::-webkit-scrollbar {
  display: none;
  height: 3px;
} 
 .company-img-partner:hover ::-webkit-scrollbar {
  display: block !important;
  
} 

.company-img-partner img {
  position: relative;
  height: 60px;
  aspect-ratio: 4/4;
}

.company-img-partner div {
  position: relative;
}

.company-img-partner div:hover::after {
  opacity: 1 !important;
  visibility: inherit !important;
  -webkit-user-select: inherit !important;
  -moz-user-select: inherit !important;
  -ms-user-select: inherit !important;
  user-select: inherit !important;
}

.company-img-partner div::after {
  content: attr(name-company);
  min-width: 80px;
  position: absolute;
  bottom: 5px;
  background-color: #151c37;
  left: 0;
  bottom: -7px;
  text-align: center;
  left: 50%;
  padding: 5px 10px;
  transform: translateX(-50%);
  border-radius: 35px;
  opacity: 0;
  visibility: hidden;
  -webkit-user-select: none;
  user-select: none;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

/* company-img-container */
.company-img-container{
  width: 700px;
  margin: auto;
  margin-top: 20px;
}
.company-img-container img{
  height: 110px;
}

/* <!-- ======================== review-grid ======================== --> */

.review-grid {
  position: relative;
  z-index: 7;
  /* direction: rtl !important; */
}

.card-review {
  width: 300px;
  text-align: center;
  text-transform: capitalize;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  background: var(--card-color);
  padding-bottom: 30px;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  color: var(--color-text);
  overflow: hidden;
}

.swiper-slide {
  min-height: 170px !important;
  height: initial !important;
}

.img-reviewer {
  width: 60px;
  height: 60px;
  background: var(--card-color);
  border-radius: 15px;
  overflow: hidden;
  flex-shrink: 0;
}

.img-reviewer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-name-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reviewer-text {
  color: var(--blog-color-p);
  margin-top: 10px;
  text-align: initial;
}

.about-reviewer {
  text-align: start;
}

.about-reviewer p {
  color: var(--blog-color-p);
}

.about-reviewer h2 {
  font-weight: 300;
}

.star-div {
  display: flex;
  align-items: center;
  gap: 10px;

}
.star-div img{
  aspect-ratio: 4/4;
width: 20px;
}



.grid-review {
  display: inherit !important;
  gap: 20px;
  width: 100%;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  column-count: auto;
  column-gap: 16px;
  column-width: 330px;
}

.grid-review .card-review {
  margin-bottom: 16px;
  width: 100% !important;
  /* max-width: fit-content; */
}

/* <!-- ======================== blog Sec ======================== --> */

.blog-grid .icon-card {
  width: 100%;
  height: auto;
}

.blog-grid .card {
  padding-top: 20px;
  border-radius: 42px;
  -webkit-border-radius: 42px;
  -moz-border-radius: 42px;
  -ms-border-radius: 42px;
  -o-border-radius: 42px;
  padding-bottom: 60px;
}

.grid-dashboard .blog-grid .card {
  padding-bottom: 90px;
}

.blog-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.blog-grid .a-img {
  height: 200px;
  overflow: hidden;
  border-radius: 25px;
  display: block;
}

.blog-grid .a-img:hover img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  cursor: pointer;
}

.blog-grid .content-card {
  margin-top: 20px;
  text-align: start;
}

.blog-grid .content-card h1 {
  font-size: 26px;
}

.blog-grid .content-card p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
}

/* ======================== why-us-sec ======================== */

.grid-why-us {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
}

.card-why-us {
  border-radius: 40px;
  border: 0.5px solid var(--stroke, rgba(180, 188, 208, 0.19));
  background: var(--card-color);
  padding: 30px;
  cursor: pointer;
  color: var(--color-text);
}

.title-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 28px;
}
.title-arrow img {
  width: 24px;
  aspect-ratio: 1/1;
}

.card-why-us p {
  color: var(--gray-color);
  transition: 0.3s;
  height: 0;
  opacity: 0;
  overflow-y: clip;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.card-why-us.active p {
  opacity: 1;
  margin: 5px 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 20px;
}

/* <!-- ======================== phone-app-sec ======================== --> */

.phone-app-img {
  width: 100%;
}

.phone-app-sec .button {
  width: fit-content;
  margin: auto;
}

.app-phone {
  width: 100%;
  min-height: 716px;
  background-color: var(--sec-black-color);
  padding-top: 20px 0;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 660px;
}

.app {
  width: 100%;
  height: 400px;
  background-color: var(--main-color);
  border-radius: 48px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse;
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  -ms-border-radius: 48px;
  -o-border-radius: 48px;
}

.app-img {
  width: 320px;
  -webkit-filter: drop-shadow(35px 10px 20px #00000087);
  filter: drop-shadow(35px 10px 20px #00000087);

  aspect-ratio: 6/12.2;
}

.app-text {
  text-align: center;
  color: var(--wight-color);
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.app-text h2 {
  color: var(--wight-color);
}

.app-text p {
  color: #d7e2ff;
}

.app .button {
  background-color: var(--Dark-color);
  width: fit-content;
  margin: auto;
}

.app-text .download-a-div {
  justify-content: center;
}

.h1-app-text {
  margin-bottom: 10px;
}

.stares-div {
  display: flex;
  justify-content: center;
}

.stares-div .stare {
  cursor: pointer;
}

.img-app-download {
  margin-top: 20px;
}

.img-app-download img {
  cursor: pointer;
  background-color: #2a2d2c;
  border-radius: 5px;
}

/* <!-- ======================== form ======================== --> */
.form-div-parent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  gap: 20px;
}

@media (max-width: 1100px) {
  .form-div-parent {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.name-phone-div {
  display: flex;
  gap: 10px;
}

.form-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  z-index: 55;
}

.form-container form input {
  padding: 10px;
  color: var(--color-text);
  font-size: 20px;
  background: transparent;
}

.form-container button {
  padding: 20px 0;
  background-color: var(--main-color);
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 18px;
}

.form-container form input::placeholder,
textarea::placeholder {
  color: var(--blog-color-p);
  font-size: 16px;
  text-transform: capitalize;
}

section {
  position: relative;
  z-index: 5;
  overflow-x: clip;
}

.section-contact-page {
  margin-top: 130px;
}

textarea {
  width: 100%;
  height: 361px;
  max-width: 100%;
  max-height: 410px;
  font-size: 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  width: 100%;
  resize: none;
  background: var(--card-color);

  color: var(--color-text);
}

.div-img-input {
  display: flex;
  align-items: center;
  background: var(--card-color);
  padding: 15px 20px;
  border-radius: 15px;
  width: 100%;
  gap: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;

  /* border: 1px solid #2a185b; */
}

.div-img-input img {
  width: 25px;
  filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg)
    brightness(107%) contrast(105%);

    aspect-ratio: 1/1;
}

.textarea-div {
  align-items: start !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

input {
  width: 100%;
}

@media (max-width: 700px) {
  .name-phone-div {
    flex-direction: column;
  }
}

.contact-about {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.details-about-contact {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details-about-contact p {
  color: var(--color-text);
}

.details-about-contact img {
  width: 26px;
  filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg)
    brightness(107%) contrast(105%);
  -webkit-filter: invert(44%) sepia(91%) saturate(1698%) hue-rotate(171deg)
    brightness(107%) contrast(105%);

    aspect-ratio: 1/1;
}
.details-about-contact b {
  text-transform: capitalize;
}

/* <!-- ======================== blog-article ======================== --> */

.blog-article {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.div-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.date-title {
  text-align: center;
}

.img-blog {
  width: 100% !important;
  border-radius: 25px !important;
  max-width: 800px;
  margin: auto !important;
  display: flex;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  -o-border-radius: 25px !important;

  aspect-ratio: 16/9;
  object-fit: cover;
}

.div-content p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--Dark2-color);
}

/* <!-- ======================== admin ======================== --> */
/* <!-- ======================== Createblog ======================== --> */

.main-div {
  margin-top: 110px;
  margin-bottom: 50px;
}

.container-admin {
  width: 90%;
  margin: auto;
}

.container-admin form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input {
  display: flex;
  align-items: center;
  background: var(--card-color);
  padding: 15px 20px;
  border-radius: 15px;
  width: 100%;
  gap: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.main-div .button {
  width: 100%;
}

.ql-editor {
  min-height: 300px;
}

.edit-trash-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.edit-trash-icon img {
  width: 25px;
  height: 25px;
}

.icon-img-blog {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
  bottom: 12px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.trashIcon {
  background-color: #ef6061;
}

.editIcon {
  background-color: #1ef3d7;
}

.icon-img-blog:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

/* <!-- ======================== footer ======================== --> */
footer {
  margin-top: 140px;
  color: var(--gray-color);
  position: relative;
  padding: 50px 0;
}

footer::after {
  content: "";
  width: 80%;
  position: absolute;
  top: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(2, 0, 36, 0)),
    color-stop(49%, rgb(64, 79, 110)),
    to(rgba(0, 212, 255, 0))
  );
  background: -o-linear-gradient(
    left,
    rgba(2, 0, 36, 0) 0%,
    rgb(64, 79, 110) 49%,
    rgba(0, 212, 255, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgb(64, 79, 110) 49%,
    rgba(0, 212, 255, 0) 100%
  );
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.logo-footer-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.googlePlay-button img {
  width: 120px;
  aspect-ratio: 6/1.51;
}
.about-p-footer {
  margin: 20px 0;
  max-width: 700px;
}

footer .list-website-footer {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: 30px 0;
}

footer .list-website-footer a {
  color: var(--color-text);
  font-weight: 700;
}

.the-end-of-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-ul-phone a {
  color: var(--wight-color);
}

@media (max-width: 500px) {
  .the-end-of-footer {
    flex-direction: column;
  }
}

/* <!-- ============================ register-container ============================ --> */

.register-container {
  margin-top: 150px;
  height: 50vh;
  margin-bottom: 220px;
}
.register-container-parent {
  margin-bottom: 330px;
}

.form-register-parent .el1 {
  top: -70px;
}

.card-data .h1-text {
  font-size: 53px;
  margin-bottom: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-register {
  width: 90%;
  margin: auto;
  text-align: center;
  border-radius: 15px;
  padding: 20px 10px;
  width: 490px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e3eefd;

  padding: 50px 20px;
}

.form-register .logo-img-fom {
  width: 210px;
  margin: auto;
  margin-bottom: 10px;
}

.form-register input {
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  border: none;
  outline: none;

  height: 50px;
  font-size: 16px;
}

.form-login .a-logo-form {
  margin-bottom: 60px;
}

.register-up-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.react-tel-input .form-control {
  width: 100% !important;
  border: 0 !important;
}

.react-tel-input .country-list .country-name {
  color: var(--Dark-color);
}

.react-tel-input .form-control:focus {
  border: 0 !important;
  box-shadow: inherit !important;
}
.react-tel-input .country-list::-webkit-scrollbar {
  display: none;
}

.a-logo-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.button-form {
  padding: var(--button-padding);
  background: var(--main-color);
  border-radius: var(--main-border-radius);
  width: fit-content;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 15px;
  border-radius: 30px;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--white-color);
  text-transform: capitalize;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.button-form:hover {
  background-color: var(--main-color-hover);
}

.check-box-text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.check-box-text #checkbox-form {
  width: 20px;
  height: 20px;
}

@media (max-width: 500px) {
  .card-data {
    margin: 50px 0;
    width: 100%;
  }

  .container-card {
    width: 100% !important;
  }

  .form-register {
    width: 100% !important;
  }
}

.url-your-website {
  text-align: initial;
}

.container-card {
  position: relative;
  width: fit-content;
  margin: auto;
}

.sing-in {
  text-align: center;
}

.sing-in a {
  color: var(--main-color);
  cursor: pointer;
  text-align: initial;
}

.forgot-pass-a {
  text-align: end;
  color: #545d93;
}

.sing-up-div {
  display: none;
}

.sing-up-div.active {
  display: block !important;
}

@media (max-width: 700px) {
  .element-img {
    display: none !important;
    height: 648px !important;
  }
}

b {
  color: var(--Dark2-color);
}

.Passwords-not-match {
  color: red;
}

.Passwords-not-match.disabled {
  display: none;
}

.form-sing-up.disabled {
  display: none;
}

.otp-sing-up.disabled {
  display: none;
}

.change-Password.disabled {
  display: none;
}

.email-send-div .pop-div-text::after,
.div-pop .h1-text::after {
  background: transparent !important;
}

.email-send-div.disabled {
  display: none;
}

.resent-the-code {
  text-align: end;
}

.resent-the-code a {
  color: red;
  cursor: pointer;
}

/* style element login */

.el2 {
  top: 218px !important;
}

.element-img {
  position: absolute;
  width: 100px;
}

@keyframes moveimg {
  0% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(10px);
  }
}

.el1 {
  left: -40px;
  top: 10px;
  animation: moveimg 2s ease infinite;
}

.el2 {
  right: -90px;
  top: 180px;
  animation: moveimg 3s ease infinite;
}

.el3 {
  left: -37px;
  bottom: -40px;
  animation: moveimg 4s ease infinite;
}

.el4 {
  right: -60px;
  bottom: 30px;
  animation: moveimg 5s ease infinite;
}

/* <!-- ============================  alert ============================ --> */
.alert-div-parent {
  padding: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: var(--Dark2-color);
  width: 90%;
  max-width: 590px;
  text-transform: capitalize;
  transition: 0.3s;
  position: fixed;
  top: -100%;
  z-index: 55555555555555555;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.alert-div-parent.active {
  z-index: 55555555555555555;
  top: 90px;
}

.icon-alert {
  display: flex;
}

.icon-alert img {
  width: 30px;
}

.alert-div-parent p {
  color: var(--Dark-color);
  text-align: initial;
}

.alert-error {
  background: #fdedef;
  border: 1px solid #f04c62;
}

.alert-error img {
  filter: invert(53%) sepia(90%) saturate(4493%) hue-rotate(326deg)
    brightness(99%) contrast(90%);
  -webkit-filter: invert(53%) sepia(90%) saturate(4493%) hue-rotate(326deg)
    brightness(99%) contrast(90%);
}

.alert-warning {
  background: #fff9e9;
}

.alert-warning img {
  filter: invert(91%) sepia(11%) saturate(5734%) hue-rotate(333deg)
    brightness(103%) contrast(99%);
}

.alert-info {
  background: #e6fafd;
}

.alert-info img {
  filter: invert(63%) sepia(59%) saturate(2982%) hue-rotate(143deg)
    brightness(102%) contrast(101%);
}

.alert-success {
  background: #eafbf6;
  border: #37d19c 1px solid;
}

.alert-success img {
  filter: invert(83%) sepia(11%) saturate(2588%) hue-rotate(98deg)
    brightness(89%) contrast(82%);
}

/* <!-- ======================== book-now ======================== --> */

.grid-book-now .card {
  padding: 30px;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 33px;
  overflow: hidden;
}

.grid-book-now .card::after {
  content: "";
  width: 70%;
  height: 15px;
  background-color: var(--main-color);
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  border-radius: 45px;
}

.grid-book-now .card .p-name {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.grid-book-now .card .button {
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}

.grid-book-now .card sub {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #556987;
}

.price-book {
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
  line-height: 90px;
  /* 125% */
  letter-spacing: -1.44px;
}

.price-currency {
  font-size: 34px;
  position: relative;
  left: -15px;
}

.advantages-div {
  display: flex;
  gap: 5px;
  align-items: center;
}

.advantages-parent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* <!-- ============================ head-of-sec-parent ============================ --> */

.head-of-sec-parent {
  height: 300px;
  width: 100%;
  background: var(--main-color);
  border-radius: 0 0 115px 115px;
  position: relative;
}

.head-of-sec-card {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: absolute;
  width: 90%;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.p-name {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.card-head {
  text-transform: capitalize;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  border-radius: 12px;
  background: var(--card-color);
  box-shadow: 0px 24px 54px 0px #dfe2ee;
  position: relative;
  z-index: 1;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  width: 300px;
}

.div-number-card-dashboard-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div-number-card-dashboard-icon h2 {
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #333f51;
}

.div-number-card-dashboard-icon .icon {
  width: 60px;
  height: 60px;
}

.number-line {
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.number-line .line-span {
  width: 60%;
  height: 3px;
  background-color: var(--main-color);
  display: block;
}

@media (max-width: 700px) {
  .head-of-sec-parent {
    display: none;
  }
}

/* <!-- ============================ grid-dashboard ============================ --> */
.grid-dashboard {
  display: grid;
  grid-template-columns: 290px 1fr;
}

.grid-dashboard .blog-grid {
  margin-bottom: 50px;
}

@media (max-width: 1000px) {
  .grid-dashboard {
    grid-template-columns: 1fr;
  }

  .nav-par-side {
    position: fixed !important;
    z-index: 555 !important;
    left: -100%;
  }

  .header-dashboard .toggle {
    display: flex !important;
  }
}

.nav-par-side.active {
  left: 0;
}

/* <!-- ============================ card-message ============================ --> */

.grid-parent {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 20px;
  position: relative;
  z-index: 1;
  margin-top: 55px;
  width: 100%;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}

.grid-div .card-div {
  height: 100% !important;
}

.card-div {
  background: var(--card-color);
  border-radius: var(--main-5-border-radius);
  position: relative;
  padding: 30px 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--main-color);
  height: 100% !important;
}

.card-div h2 {
  text-align: center;
  margin-bottom: 15px;

  padding-bottom: 15px;
  position: relative;
  text-transform: capitalize;
}

.card-div h2::after {
  content: "";
  width: 70%;
  height: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(2, 0, 36, 0)),
    var(--main-color),
    to(rgba(0, 212, 255, 0))
  );
  background: -o-linear-gradient(
    left,
    rgba(2, 0, 36, 0) 0%,
    var(--main-color),
    rgba(0, 212, 255, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    var(--main-color),
    rgba(0, 212, 255, 0) 100%
  );
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  border-radius: 45px;
  width: 100%;
}

.card-div .label-p {
  margin-top: var(--main-margin);
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
  padding-top: var(--main-10-padding);
  margin-top: var(--main-margin);
  text-align: center;

  color: var(--color-text);
}

.card-div p.active {
  -webkit-line-clamp: initial;
}

.p-card-parent {
  text-align: center;
  cursor: pointer;
}

.p-card-parent .show-more {
  color: var(--main-color);
}

.icon-a-card {
  width: 50px;
  height: 50px;
  background-color: var(--main-color);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  position: relative;
  z-index: 1;
}

.icon-a-card:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.icon-a-card img {
  height: 24px;
  filter: invert(1);
  -webkit-filter: invert(1);
}

.email-phone-div {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.p-card {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.delete-icon-card {
  background-color: #ff5e5e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
  position: absolute;
  left: 10px;
  bottom: 10px;
}

/* <!-- ============================ card-appointment ============================ --> */
.grid-card-dashboard {
  position: relative;
  z-index: 1;
  margin-top: 55px;
  width: 90%;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  column-count: auto;
  column-gap: 16px;
  column-width: 330px;
  padding-bottom: 30px;
}

.name-ot-meeting-div-remove {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-appointment {
  text-transform: capitalize;
  padding: 10px;
  border-radius: 10px;
  border-radius: 12px;
  background: var(--card-color);
  z-index: 0 !;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.time-date {
  padding: 10px;
  border-radius: 5px;
  background: var(--background-color);
  display: flex;
  flex-direction: column;
  gap: 10px;

  position: relative;
}

.time-date h2 {
  font-size: 25px;
}

.time-date sub {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: var(--color-text);
}

.span-number {
  position: absolute;
  right: 0px;
  top: 1px;
  background: #02b3ff;
  width: 30px;
  height: 30px;
  display: block;
  z-index: 55;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 10px;
}

.img-prof {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
}

.card-appointment p {
  font-weight: 600 !important;
}

.img-prof img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-profile-name-contact {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.img-name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.contact {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact img {
  cursor: pointer;
}

.remove-done {
  display: flex;
  align-items: center;
  gap: 10px;
}

.remove-done img {
  cursor: pointer;
  width: 50px;
}

.category-length {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.category-length span {
  width: 40px;
  height: 40px;
  background: #e9ebff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--main-color);
  font-weight: 700;
  font-size: 20px;
  color: #333f51;
}

.name-company-h1 {
  text-align: center;
}

.company-div {
  position: relative;
  padding-top: 20px;
}

.company-div::after {
  content: "";
  width: 70%;
  height: 10px;
  background-color: var(--main-color);
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  border-radius: 45px;
}

.company-div .add-new-date {
  min-height: 120px !important;
  margin-bottom: 0px !important;
}

.grid-company .add-new {
  min-height: 310px !important;
  margin-bottom: 20px !important;
}

.code-div {
  border-radius: 60px;
  background: var(--main-color, #00b2ff);
  display: inline-flex;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--wight-color, #fff);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-transform: initial !important;
}

.company-div .name-ot-meeting-div-remove {
  flex-direction: column;
  gap: 20px;
}

/* <!-- ======================== active-video-pop ======================== --> */

.active-video-pop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(20px);
  z-index: 555555;
  background: #0b051a46;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  top: -100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.active-video-pop.active {
  top: 0;
  opacity: 1;
  pointer-events: initial;
  visibility: initial;
}

.closex {
  position: absolute;
  z-index: 55;
  background: var(--main-color);
  top: 0;
  width: 60px;
  height: 60px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  cursor: pointer;
}

.notification-div .closex {
  background: var(--main-color);
  top: 0;
  width: 60px;
  height: 60px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.closex .iconDefault {
  width: 40%;
  aspect-ratio: 1/1;
}

.frame-pop-video {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  border-radius: 5px;
  overflow: hidden;
  aspect-ratio: 2/1.13;
  width: 70%;
}

iframe {
  aspect-ratio: 2/1.13;
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 750px) {
  .frame-pop-video {
    width: 90% !important;
  }
}

/* <!-- ============================ header-dashboard ============================ --> */
.header-dashboard {
  height: 80px;
  background-color: var(--Dark-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 55;
}

.toggle-div-parent {
  display: flex;
  gap: 10px;
  align-items: center;
}

.toggle-dashboard {
  padding: 10px;
  display: flex;
}

@media (max-width: 1000px) {
  .header-dashboard .toggle-div-parent {
    justify-content: space-between;
    width: 100%;
  }
}

/* --- */

.account-parent {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border-radius: var(--main-border);
  cursor: pointer;
  position: relative;
  width: 100%;
  justify-content: space-between;
  user-select: none;
  max-width: 300px;
  background-color: #eef0ff;
  border-radius: 5px;
}

/* .chevron-down {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(352deg)    brightness(104%) contrast(101%);
  -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(352deg);
} */

.account-parent:hover {
  background-color: #f5f6ff;
}

.account-parent .chevron-down {
  transition: 0.2s;
  width: 15px;
  height: 15px;
}

.account-parent:hover .chevron-down {
  transform: translateY(2px);
}

.account-parent .chevron-down.active {
  transform: translateY(2px);
}

.img-account {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 5px;
  flex-shrink: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.account-name-photo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-account img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name-username {
  display: flex;
  flex-direction: column;
}

.name-username b {
  height: 17px;
}

.name-username .name-user {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--color-text);
}

.name-username p {
  color: var(--blog-color-p);
}

.down-li-account-parent {
  position: absolute;
  top: 114%;
  width: 100%;
  right: 0;
  padding: 5px;
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  display: none;
  background: #eef0ff;

}
.down-li-account-parent p{
  color: var(--color-text) !important;

}

.down-li-account-parent-ar {
  right: 0 !important;
}

.down-li-account-parent.active {
  display: block;
}

.down-li-account-parent-arabic {
  right: 0;
}

.down-li-account-parent li a {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-transform: capitalize;
  padding: 10px 5px;
  border-radius: 5px;
  align-items: center;
}



.down-li-account-parent li a:hover {
  background-color: #f5f6ff;
}

.down-li-account-parent li a img {
  width: 20px !important;
  height: 20px !important;
  filter: invert(67%) sepia(7%) saturate(1265%) hue-rotate(182deg) brightness(92%) contrast(99%);
  -webkit-filter: invert(67%) sepia(7%) saturate(1265%) hue-rotate(182deg) brightness(92%) contrast(99%);
}

/* <!-- ============================ nav-par-side ============================ --> */

.nav-par-side {
  width: 290px;
  height: 100vh;
  background: var(--Dark-color);
  padding-right: 20px;
  padding-top: 20px;
  position: sticky;
  top: 0;
  border-right: 1px solid #404859;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;

  overflow-y: scroll;
}

.nav-par-side::-webkit-scrollbar {
  background-color: var(--Dark-color);
}

.nav-sec {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-sec a {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 0 45px 45px 0;
  gap: 20px;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  -ms-transition: 0.1s;
  -o-transition: 0.1s;
  text-transform: capitalize;
  justify-content: space-between;
}

.nav-sec a:hover {
  background: #262e3f;
}

.nav-sec a.active {
  background: var(--main-color);
}

.a-logo-dashboard {
  margin-bottom: 20px !important;
  display: block;
  padding: 20px;
}

.list-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-sec span {
  display: block;
  width: 40px;
  height: 40px;
  background: rgb(45 51 63);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}

/* <!-- ======================== div-pop ======================== --> */

.div-pop {
  position: fixed;
  width: 100%;
  height: 100vh;

  left: 0;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: 555;
  background: #0b051a46;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: -100%;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;

  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.div-pop .card-div {
  height: fit-content !important;
}

.div-pop.active {
  overflow: auto;
  top: 0;
  overflow: auto;
  opacity: 1;
  pointer-events: initial;
  visibility: initial;
}

select {
  display: flex;
  align-items: center;
  background: var(--card-color);
  padding: 15px 20px;
  border-radius: 15px;
  width: 100%;
  gap: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border: 1px solid var(--main-color);
}

.social-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  margin-top: 20px;
  position: relative;
}

.social-media .icon-a-card {
  margin: 0;
}

.div-pop .h1-text {
  font-size: 36px;
  margin: 30px 0;
  padding-bottom: -10px;
  text-align: center;
  margin-bottom: 10px;
}

.subscribe-div-text {
  margin-bottom: 30px;
}

.add-website input {
  font-size: 16px;
  border: 1px solid var(--main-color);
  background-color: var(--wight-color);
}

.label-p {
  text-transform: capitalize;
  text-align: start;
  display: initial !important;
  margin-top: 25px !important;
  padding-left: 5px;
}

.div-pop .h1-text::after {
  content: "";
  width: 70%;
  height: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(2, 0, 36, 0)),
    color-stop(49%, rgb(64, 79, 110)),
    to(rgba(0, 212, 255, 0))
  );
  background: -o-linear-gradient(
    left,
    rgba(2, 0, 36, 0) 0%,
    rgb(64, 79, 110) 49%,
    rgba(0, 212, 255, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgb(64, 79, 110) 49%,
    rgba(0, 212, 255, 0) 100%
  );
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  border-radius: 45px;
  width: 100%;
}

.div-pop .card-div {
  width: 90%;
  max-width: 630px;
  position: relative;
  background: var(--card-color);
  padding: 10px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  border: 1px solid var(--main-color);
}

.div-pop .card-div p {
  display: inherit;
}

.subscribe-div-text p {
  text-align: center;
  text-transform: capitalize;
}

.x-button {
  padding: 15px;
  background: var(--main-color);
  border-radius: 5px;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  z-index: 5;
}

.x-button img {
  width: 80%;
}

.x-button:hover {
  background: var(--main-color-hover);
}

.x-div-github {
  width: 40px;
  height: 40px;
}

.div-git-hub {
  position: relative;
}

.list-link-git-hub {
  position: absolute;
  z-index: 5;
  bottom: 60px;
  background: #292d45;
  padding: 20px;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  left: 0;

  width: 90%;
  -webkit-box-shadow: 0px 20px 215px 510px #181c33;
  box-shadow: 0px 20px 215px 510px #181c33;
  padding-top: 45px;
  max-width: 640px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  height: 470px;
  top: 50%;
  opacity: 0;
  visibility: hidden;
}

.list-link-git-hub.active {
  opacity: 1;
  visibility: initial;
}

.a-link-github {
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  max-height: 330px;
}

.list-link-git-hub::after {
  content: "";
  width: 70%;
  height: 10px;
  background-color: var(--main-color);
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  border-radius: 45px;
}

.a-link-github::-webkit-scrollbar {
  height: 5px;
  background: none;
}

.a-link-github a:hover {
  color: var(--main-color);
}

.icon-git-hub {
  cursor: pointer;
}

.github-pop-div .card-div .a-CVE-pop h2 {
  padding: 20px 0;
  border-bottom: 0;
  margin-top: 40px;
  padding-bottom: 0;
}

.github-pop-div .card-div .a-CVE-pop h2::after {
  top: 0;
}

.github-pop-div .card-div .a-CVE-pop {
  position: relative;
  z-index: 5;
}

.img-prview {
  width: 100%;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.input-time {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.value-time {
  padding: 10px;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 20px;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-time input {
  max-width: 100px;
  height: 67px;
}

/* <!-- ============================ card-date ============================ --> */

.card-date-grid {
  position: relative;
  z-index: 1;
  margin-top: 55px;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  gap: 20px;
  margin: 50px auto;
}

.card-date-grid-div {
  margin-top: 60px;
  gap: 20px;
}

.card-div-message {
  margin-bottom: 20px;
}

.card-date {
  text-transform: capitalize;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  border-radius: 12px;
  background: var(--card-color);
  position: relative;
  z-index: 1;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
  position: relative;
}

.card-date::after {
  content: "";
  width: 70%;
  height: 10px;
  background-color: var(--main-color);
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  border-radius: 45px;
}

.card-date .time-date h2 {
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}

.buttons-date {
  display: flex;
  width: 100%;
  gap: 10px;
}

.buttons-date button {
  width: 100%;
  padding: 20px;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-radius: 10px;
}

.buttons-date .delete-button {
  background: #ff6d6d;
  color: var(--wight-color);
}

.buttons-date .available-button {
  background: #6dffdc;
}

.add-new-date {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
  min-height: 200px;
}

.form-subscribe {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: start;
  align-items: start;
  width: 100%;
}

.form-subscribe textarea {
  border-radius: 5px;
  padding: 15px;
  border: 1px solid var(--main-color);
  max-height: 140px;
}

/* <!-- ============================ Setting ============================ --> */
.choose-file-input {
  display: none !important;
}

.setting-div {
  margin-top: 100px;
}

.choose-file-label {
  position: absolute;
  top: -15px;
  width: 40px;
  height: 40px;
  background: var(--Dark2-color-hover);
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: -15px;
  box-shadow: 1px 1px 20px #08201dc4;
}

.choose-file-label img {
  width: 50%;
  height: fit-content;
}

.avatar-div {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 5px;
  margin: auto;
  user-select: none;
}

.avatar-div-parent {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin: auto;
  position: relative;
}

.name-username-avatar-div {
  text-align: center;
  margin-top: 10px;
}

.name-avatar {
  font-size: 20px;
}

.user-name-avatar {
  margin-top: -5px;
  color: var(--gray-color);
}

.avatar-div .avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-header-setting .money-change-lang {
  display: inherit !important;
}

@media (max-width: 400px) {
  .container-header-setting .money-change-lang-pars-parent {
    transform: scale(0.8);
  }
}

.input-part-parent input {
  background: var(--gray-color);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  border: none;
  outline: none;
  color: var(--wight-color);
  height: 50px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.input-part-parent {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  text-transform: capitalize;
  color: var(--blog-color-p);
}

.input-part-parent .input-label {
  flex: 1 1 300px;
}

.input-label label {
  color: var(--color-text);
}

.input-label input {
  background-color: #ffffff !important;
  color: #495057 !important;
  margin-top: 5px;
}

.input-part-parent:not(:first-child) {
  margin: 65px 0 0 0;
  padding-top: 20px;
  border-top: 1px solid var(--main-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.payment-div-parent {
  margin: 65px 0 0 0;
  padding-top: 20px;
  border-top: 1px solid #3b645d;
}

.payment-div {
  display: flex;
  gap: 10px;
  overflow: overlay;
  padding-bottom: 15px;
  scroll-snap-type: x mandatory;
}

.payment-div::-webkit-scrollbar {
  height: 4px;
}

.payment-div::-webkit-scrollbar-thumb {
  background-color: #00ff77;
}

.payment-choose {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  background: var(--card2-color);
  border-radius: 5px;
  width: 180px;
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  position: relative;
  flex-shrink: 0;
  user-select: none;
  scroll-snap-align: start;
}

.payment-choose img {
  filter: var(--main-color-icon);
}

.payment-choose.active::after {
  content: "";
  width: 20px;
  height: 100%;
  position: absolute;
  background-color: var(--main-color);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  left: -3px;
}

.payment-toggle-parent {
  position: relative;
}

.payment-toggle-div {
  display: none;
  width: 100%;
}

.payment-toggle-div.active {
  display: block;
}

.payment-toggle-div .input-part-parent {
  margin-top: 20px;
}

.setting-div .button {
  width: 100%;
  margin: 20px 0;
}

.email-setting {
  margin: 30px 0 !important;
  text-transform: initial !important;
}

/* <!-- ============================ Order ============================ --> */

.paypal-buttons:last-child {
  display: none !important;
}

.form-coupon input {
  background: var(--background-color);
}

.form-coupon {
  display: flex;
  flex-direction: column;
  gap: 10px;
  display: none;
}

.form-coupon.active {
  display: flex;
}

.div-coupon-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button:hover .icon-pay{
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.icon-pay{
  width: 23px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.div-coupon-form button {
  background: #06133f  !important;
}

.div-coupon-form button:hover {
  background: #091950  !important;
}

.card-coupon {
  height: fit-content;
}

/* <!-- ======================== not found ======================== --> */

.not-found-parent {
  height: 40vh;
  overflow: hidden;
  margin-top: 140px;

  animation: moveUpDw 2s infinite;
  -webkit-animation: moveUpDw 2s infinite;
}

.not-found-parent img {
  height: 100%;
  width: 100%;
}

@keyframes moveUpDw {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .not-found-parent {
    height: 40vh;
  }
}

.not-found-parent-appointment img {
  height: 100%;
}

.div-NotFound-button {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  max-width: 590px;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
}

.div-NotFound-button p {
  text-align: center;
}

/* <!-- ======================== @media ======================== --> */

@media (max-width: 900px) {
  .list-links {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .login {
    display: none;
  }

  .phone-header .list-website.active {
    right: 0;
  }
  .phone-header .list-website {
    display: flex;
  }

  .list-website li {
    padding: 10px;
  }

  .toggle {
    display: block;
  }

  .list-website {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    position: fixed;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    background: var(--side-bar-color);
    bottom: 0;
    top: 83px;
    right: 0;
    width: 70%;
    padding: 20px;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    z-index: 5555;
    border-left: 1px solid #3f4e6c;
    right: -100%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
  }

  .login-span {
    color: var(--wight-color);
    min-width: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 95px;
    background: var(--main-color);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 15px;
    border-radius: 40px;
    margin: 0 auto;
  }

  .a-login {
    margin: 0 auto;
  }

  .register-div,
  .a-logo {
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
  }
}

@media (min-width: 900px) {
  .toggle {
    display: none;
  }

  .li-login {
    display: none;
  }
}

@media (max-width: 1000px) {
  .app {
    display: block;
  }
  .style-img-div {
    display: none !important;
  }
  .app-img {
    position: absolute;
    top: -223px;
    left: 50%;
    transform: translateX(-50%) !important;
  }

  .app-text {
    position: relative;
    z-index: 5;
    bottom: -230px;
    padding: 20px 0;
    color: var(--wight-color);
    width: 100% !important;
    max-width: inherit;
  }

  .app-text::after {
    content: "";
    width: 100%;
    height: 201px;
    background-color: var(--main-color);
    position: absolute;
    left: 50%;
    top: 0;

    transform: translateX(-50%);
    z-index: -1;
    box-shadow: 0px -20px 20px #00000047;
    -webkit-backdrop-filter: blur(54px);
    -moz-backdrop-filter: blur(54px);
    -ms-backdrop-filter: blur(54px);
    backdrop-filter: blur(54px);
    border-radius: 0 0 48px 48px;
    -webkit-border-radius: 0 0 48px 48px;
    -moz-border-radius: 0 0 48px 48px;
    -ms-border-radius: 0 0 48px 48px;
    -o-border-radius: 0 0 48px 48px;
  }

  .app-container {
    height: 917px;
  }

  .img-app-download {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }

  .img-app-download img {
    width: 80%;
  }

  .stares-div {
    transform: scale(0.7);
  }

  .app-text::after {
    height: 377px;
  }
}

/* <!-- ============================ Blog Page ============================ --> */

.grid-content {
  
  margin-top: 40px;
}

/* <!-- ============================ Blog Page ============================ --> */

.blog-details-flow {
  position: relative;
  margin-top: -200px;
}

@media (max-width: 1666px) {
  .blog-details-flow {
    position: relative;
    margin-top: -100px;
  }
}

@media (max-width: 500px) {
  .blog-details-flow {
    margin-top: -55px;
  }
}

.check-mark-ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.check-mark-ul li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check-mark-ul img {
  width: 26px !important;
  filter: invert(62%) sepia(91%) saturate(613%) hue-rotate(1deg)
    brightness(103%) contrast(104%);
  height: 26px;
}

/* <!-- ============================ Blog Page ============================ --> */

.color-p {
  color: var(--main-color);
  margin: 0 5px;

}

.code-p {
  
  padding: 0 5px;
  border-radius: 5px;
  background: var(--main-color);
  color: white;
}

.blog-page h1 {
  /* font-size: 35px; */
  font-size: 1.7rem;
  line-height: 45px;
  margin-bottom: 20px;
  margin: 5px 0;
  margin-bottom: 10px;
  font-weight: 400;
  position: relative;
}

.blog-page h2 {
  font-size: 30px;
}

.blog-page h3 {
  font-size: 25px;
  margin: 5px 0;
}

.blog-page h4 {
  font-size: 20px;
  margin: 5px 0;
}

.a-in-blog {
  border-bottom: 1px solid var(--main-color);
  color: var(--Dark2-color);
  font-weight: bold;
  margin: 0 5px;
}

.data-category-personHowoPost ul {
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 5px 0;
  /* margin-bottom: 10px; */
  /* border-bottom: 1px solid var(--Dark2-color); */
  /* padding-bottom: 15px; */
}

/* <!-- ============================ Default Style Blog ============================ --> */

.a-link {
  font-size: 1rem;
  background: var(--main-color);
  padding: 10px 25px;
  border-radius: 5px;
  width: max-content;
  margin: auto;
  display: flex;
  transition: 0.2s;
  margin: 40px auto;
  color: var(--background-2-color);
  font-weight: 500;
  position: relative;
}

.a-link:hover {
  background-color: var(--main-color-hover);
}

.blog-details h2,
.blog-details h3,
.blog-details h4,
.blog-details h5,
.blog-details h6 {
  margin: 20px 0 !important;
  padding-bottom: 11px;
  width: fit-content;
  font-size: 30px !important;
  color: var(--main-color);
}

.blog-details h1 {
  font-size: 35px;
}

.blog-details h2 {
  font-size: 30px;
}

.blog-details h3 {
  font-size: 25px;
  margin: 5px 0;
}

.blog-details h4 {
  font-size: 20px;
  margin: 5px 0;
}

.blog-details ul {
  margin-bottom: 10px;
}

.blog-details ul li {
  font-weight: 300;
  font-size: 1rem;
  border-bottom: 1px solid var(--Dark-color);
  padding-bottom: 9px;
  padding-top: 7px;
  padding-left: 5px;
}

.simple-ul {
  margin: 20px 0;
}

.simple-ul li {
  list-style: inside;
}

.simple-ul li:where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--main-color);
}

.basic-ul li {
  list-style: decimal inside;
}

.list-blog {
  display: flex;
  gap: 5px;
  color: var(--blog-color-p);
}

blockquote {
  color: var(--blog-color-p);
  border-left: 5px solid var(--main-color);
  height: fit-content;
  padding-left: 10px;
  font-size: 19px;
  color: #8181a1;
}

.list-blog a {
  color: var(--blog-color-p);
}

.img-app-blog {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: var(--main-border);
  position: relative;
}

.list-blog li {
  display: flex;
  gap: 10px;
}

.blog-details img {
  width: 100%;
  border-radius: 5px;
  margin: 5px 0;
  height: 100%;
  object-fit: contain;
}

.blog-details p {
  font-size: 1rem;
  margin: 5px 0;
  line-height: 30px;
}

.img-app-blog img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-page {
  padding: 10px 20px;
  border-radius: var(--main-border);
}

.details-app {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  position: relative;
  gap: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.data-app {
  background: var(--card2-color);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.title-detail-icon {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 45%;
  border-left: 1px solid #3f366c;
  margin-left: 10px;
}

.data-app .data-title {
  width: 55%;
}

.language-app-div p,
.size-app p,
.download-app-much p {
  text-transform: uppercase !important;
}

.details-app h4,
.version-details h4 {
  font-size: 14px !important;
  margin: initial !important;
  border-bottom: initial !important;
  padding-bottom: initial !important;
  width: initial !important;
}

.blog-page p {
  color: var(--blog-color-p);
  line-height: 30px;
  color: var(--color-text);
}

.blog-page .margin-text-blog,
.blog-details h1,
.blog-details h2,
.blog-details h3,
.blog-details h4,
.blog-details h5,
.blog-details h6 {
  line-height: 35px;
  font-weight: 400;
}

.blog-page li {
  color: var(--blog-color-p);
  display: flex;
  align-items: center;
  gap: 10px;
}

.blog-details li {
  font-size: 21px;
}

.blog-details .counter-increment-ul li {
  counter-increment: rascocounter;
  padding-left: 40px;
  position: relative;
  margin-top: 6px;
  border-bottom: 1px solid var(--card2-color);
  margin-bottom: 12px;
  padding-bottom: 15px;
  color: var(--Dark-color);
}

.counter-increment-ul li::after {
  content: counter(rascocounter);
  width: 20px;
  height: 20px;
  background-color: var(--main-color);
  position: absolute;
  left: 0;
  top: 15px;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  padding: 5px;
  align-items: center;
  color: var(--background-2-color);
  font-weight: 500;
}

/* <!-- ============================ Share Button ============================ --> */

.share-icon-div {
  padding: 10px;
  background-color: var(--Dark2-color);
  border-radius: 5px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}

.share-icon-div.active {
  background-color: var(--main-color);
}

.share-icon-div:active {
  transform: scale(0.9);
}

.copy-done.active {
  opacity: 1;
}

.share-icon-div img {
  width: 20px;
  height: 20px;
  filter: invert(1);
}

/* <!-- ============================ Share The Blog Page ============================ --> */

.share-the-blog-page {
  display: flex;
  justify-content: center;
  margin: 30px auto;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  width: 520px;
  flex-direction: column;
}

@media (max-width: 560px) {
  .share-the-blog-page {
    width: 100%;
  }
}

.share-the-blog-page ul {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 0;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.share-the-blog-page ul a {
  color: #fff;
  font-size: 16px;
  background-color: var(--Dark-color);
  border-radius: var(--main-border);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  border: 0;
  position: relative;
}

.share-the-blog-page ul a:hover {
  color: var(--main-color);
  box-shadow: 0 0 5px var(--main-color);
  text-shadow: 0 0 5px var(--main-color);
}

.share-the-blog-page ul a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--main-color);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.share-the-blog-page ul a:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 60px var(--main-color);
}

.share-the-blog-page ul a img {
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  z-index: 11;
}

.share-the-blog-page ul a:hover img {
  filter: invert(62%) sepia(91%) saturate(613%) hue-rotate(1deg)
    brightness(103%) contrast(104%);
}

.p-share {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* <!-- ============================ Share The Blog Page ============================ --> */

.copy-done {
  position: absolute;
  z-index: 1111111;
  left: 0;
  width: max-content;
  bottom: -62px;
  padding: 10px;

  background: var(--main-color);
  border-radius: 5px;
  color: white !important;
  cursor: auto;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
}

.copy-done::after {
  content: "";
  position: absolute;
  top: -9px;
  left: 14px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--main-color);
}

.copy-done p {
  color: var(--wight-color) !important;
}

/* <!-- ============================ Data Category ============================ --> */

.data-category-personHowoPost ul {
  display: flex;
  gap: 15px;
  align-items: center;
  /* margin: 5px 0; */
  /* margin-bottom: 10px; */
  /* border-bottom: 1px solid var(--Dark2-color); */
  /* padding-bottom: 15px; */
}

.img-blog {
  overflow: hidden;
  border-radius: var(--main-border);
  width: 100%;
  position: relative;
}

.img-blog::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgb(33 28 61 / 97%) 74%
  );
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.img-blog h1 {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
}

.img-blog img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* <!-- ============================  Margin Text Blog ============================ --> */

.margin-text-blog {
  margin: 20px 0 !important;
  line-height: 41px !important;
}

/* <!-- ============================  Version App Icon Svg ============================ --> */

.version-app-icon-svg {
  filter: invert(14%) sepia(91%) saturate(5190%) hue-rotate(269deg)
    brightness(81%) contrast(126%);
  height: 30px;
  width: 30px;
  filter: invert(1);
}

/* <!-- ============================  Blog App ============================ --> */
.div-img-blog-parent {
  position: relative;
}

.div-img-blog {
  width: 100%;
  height: 300px !important;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  border-radius: 5px;
}

.div-img-blog-parent .profile-div h2 {
  font-size: 20px !important;
}

.name-profile-about h2 {
  font-size: 20px !important;
}

.div-img-blog .background-app-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.h1-blog {
  display: flex;
  border-bottom: 6px solid var(--main-color);
  padding-bottom: 36px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.h1-blog::after {
  content: "";
  width: 15px;
  height: 15px;
  background-color: var(--main-color);
  position: absolute;
  left: 0;
  bottom: -10px;
  transform: rotate(45deg);
}

.h1-blog::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: var(--main-color);
  position: absolute;
  right: 0;
  bottom: -10px;
  transform: rotate(45deg);
}

/* ----------------------- */
.blog-main-page .blog-details {
  margin-top: 0 !important;
}

/* ========================  */
@media (max-width: 500px) {
  .blog-page h1 {
    font-size: 23px;
    line-height: 35px;
  }

  .blog-details h2 {
    font-size: 21px;
  }

  .blog-details h3 {
    font-size: 20px;
  }
}

/* <!-- ============================ ul-list-line ============================ --> */
.ul-list-line {
  margin-bottom: 10px;
  border-left: 1px solid var(--main-color);
  padding-left: 16px;
  border-left-style: dashed;
}

.ul-list-line li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ul-list-line li h2 {
  position: relative;
}

.ul-list-line li h2::after {
  content: "";
  width: 7px;
  height: 7px;
  background-color: var(--main-color);
  position: absolute;
  left: -30px;
  top: 15px;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  padding: 5px;
  align-items: center;
  color: var(--background-2-color);
  border-radius: 50%;
}

.ul-list-line li h2::before {
  content: "";
  width: 17px;
  height: 17px;
  background-color: var(--main-color);
  position: absolute;
  left: -35px;
  top: 15px;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  padding: 5px;
  align-items: center;
  color: var(--background-2-color);
  border-radius: 50%;
  opacity: 0.5;
  transform-origin: center;
}

/* <!-- ============================ Catalogue ============================ --> */

.catalogue-parent {
  padding: 10px;
  background: var(--card-color);
  border-radius: 5px;
}

.catalogue-div {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
}

.catalogue-end-of-article {
  justify-content: center;
  margin: 10px 0;
}

.catalogue-div a {
  padding: 5px 10px;
  background-color: var(--Dark2-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.catalogue-div a:hover {
  opacity: 0.8;
}

.catalogue-div img {
  width: 15px;
  height: 15px;
}

/* <!-- ============================  code-text ============================ --> */

.code-text-block {
  background-color: #222631;
  padding: 20px;
  margin: 10px 0;
  display: block;
  text-align: end;
  font-size: 20px;
  width: 100%;
}

/* <!-- ============================  words page ============================ --> */
.words-page-h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* <!-- ============================  @media ============================ --> */

@media (max-width: 900px) {
  .comment-box {
    width: 80% !important;
  }

  .comment-box .comment-head span {
    font-size: 11px;
  }

  .comment-box .comment-content {
    padding: 12px;
    font-size: 12px;
    line-height: 34px;
  }

  .comment-box .comment-name {
    font-size: 12px;
    margin: 0 5px;
  }
}

@media (max-width: 500px) {
  .another-blog-h2-img h2 {
    line-height: 28px;
    font-size: 17px !important;
  }

  .icon-another-blog {
    display: none;
  }
}

@media (max-width: 600px) {
  .li-ca-list-head {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .div-img-blog {
    height: 180px !important;
  }

  .div-img-blog-parent .profile-div .name-profile-about h2 {
    font-size: 1rem;
  }

  .profile-div .social-media {
    gap: 3px;
  }

  .blog-page h1 {
    font-size: 1.7rem;
    line-height: 30px;
    font-weight: 400;
  }

  .blog-details h2 {
    font-size: 1.3rem !important;
    font-weight: 400;
  }
}

